<template>
  <div class="_bg-default _full-w pa-5">
    <Waiting v-if="loading" />
    <notifications
      group="authVerification"
      class="mt-2"
      position="top center"
    />
    <div class="mb-5">
      <v-btn color="blue" dark depressed rounded @click="logout">
        <v-icon>mdi-logout</v-icon>
        Logout
      </v-btn>
    </div>
    <h3 class="purple--text text-center">Fill in Your Data</h3>
    <div class="d-flex justify-center">
      <!-- <div class="photo">ok</div> -->
      <div class="_70w" v-if="listProvince">
        <v-row v-if="photo">
          <v-col cols="12" md="4">
            <div class="d-flex flex-column align-center pa-3">
              <div class="temp mb-3 d-flex align-center justify-center">
                <p v-if="!photo.photo_profile">Photos will appear here</p>
                <img
                  :src="
                    `${env}/upload/photo_profile/${id}/${photo.photo_profile}`
                  "
                  alt=""
                  class="pic"
                  v-if="photo.photo_profile"
                />
              </div>
              <div class="alert">
                <small v-if="!photo.photo_profile" class="red--text"
                  >Foto harus di isi</small
                >
              </div>
              <!-- <v-btn dark color="purple" @change="upload($event)">
                <div class="upload-btn-wrapper add-width">
                  <div class="pa-1">
                    <v-icon>mdi-upload</v-icon> Upload Photo
                    <input type="file" name="myfile" />
                  </div>
                </div>
              </v-btn> -->
              <v-btn dark color="purple" @click="dialogCrop = true"
                >Upload Photo</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Full Name"
                      v-model="fullName"
                      :error-messages="nameErr"
                      required
                      dense
                      solo
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Place Of Birth"
                      required
                      :error-messages="ttlErr"
                      v-model="ttl"
                      solo
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-menu
                      v-model="calendar1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="born_date"
                          label="Date Of Birth"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="born_date"
                        @input="calendar1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group v-model="selectedGender" row>
                      <v-radio
                        v-for="q in gender"
                        :key="q.val"
                        :label="q.state"
                        :value="q.val"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="no Hp"
                      required
                      :error-messages="noHpErr"
                      v-model="noHp"
                      solo
                      dense
                      hide-details="auto"
                    ></v-text-field>
                    <v-radio-group v-model="no_hp_show" row>
                      <v-radio label="Tampilkan" :value="1"></v-radio>
                      <v-radio label="Sembunyikan" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      :options="religion"
                      :reduce="(item) => item.val"
                      v-model="selectedReligion"
                      @search="(query) => (searchReligion = query)"
                      label="state"
                      placeholder="Religion"
                      class="white"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- layaneann -->
                <div class="my-5">
                  <span class="bg pa-1">Services</span>
                  <v-divider class="mt-n3" inset></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group v-model="selectedService" row>
                      <v-radio
                        v-for="q in service"
                        :key="q.val"
                        :label="q.state"
                        :value="q.val"
                      ></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-if="
                        selectedService == 'offline' || selectedService == 'all'
                      "
                      label="Address"
                      solo
                      dense
                      required
                      hide-details="auto"
                      v-model="alamat_praktek"
                    ></v-text-field>
                    <v-text-field
                      :class="selectedService == 'all' ? 'mt-3' : ''"
                      v-if="
                        selectedService == 'online' || selectedService == 'all'
                      "
                      label="Contact and Registration"
                      solo
                      required
                      type="number"
                      dense
                      hide-details="auto"
                      v-model="no_hp_praktek"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- website -->
                <div class="my-5">
                  <span class="bg pa-1">Website</span>
                  <v-divider class="mt-n3" inset></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Website"
                      required
                      solo
                      dense
                      outlined
                      hide-details="auto"
                      v-model="website"
                    ></v-text-field>
                    <v-radio-group v-model="no_website_show" row>
                      <v-radio label="Tampilkan" :value="1"></v-radio>
                      <v-radio label="Sembunyikan" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <!-- location -->
                <div class="my-5">
                  <span class="bg pa-1">Location</span>
                  <v-divider class="mt-n3" inset></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      :options="listProvince.data"
                      :reduce="(item) => item.id"
                      v-model="selectedProvince1"
                      @search="(query) => (searchProvince = query)"
                      label="nama_provinsi"
                      placeholder="Province"
                      class="white"
                      @input="getCity(selectedProvince1, 'core')"
                    ></v-select>
                    <small
                      v-if="!this.$v.selectedProvince1.required"
                      class="red--text"
                      >{{ provErr[0] }}</small
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-if="listCity1"
                      :options="listCity1.data"
                      :reduce="(item) => item.id"
                      v-model="selectedCity1"
                      @search="(query) => (searchCity = query)"
                      label="nama_kota"
                      placeholder="City"
                      class="white"
                    ></v-select>
                    <v-select v-else placeholder="City" class="white">
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        pilih provinsi terlebih dahulu
                      </span>
                    </v-select>
                    <small
                      v-if="!this.$v.selectedCity1.required"
                      class="red--text"
                      >{{ locErr[0] }}</small
                    >
                  </v-col>
                </v-row>
                <!-- addtitional-->
                <div class="my-5">
                  <span class="bg pa-1">Additional location</span>
                  <v-divider class="mt-n3" inset></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      :options="listProvince.data"
                      :reduce="(item) => item.id"
                      v-model="selectedProvince2"
                      @search="(query) => (searchProvince = query)"
                      label="nama_provinsi"
                      placeholder="Province"
                      class="white"
                      @input="getCity(selectedProvince2, 'add')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-if="listCity2"
                      :options="listCity2.data"
                      :reduce="(item) => item.id"
                      v-model="selectedCity2"
                      @search="(query) => (searchCity = query)"
                      label="nama_kota"
                      placeholder="City"
                      class="white"
                    ></v-select>
                    <v-select v-else placeholder="City" class="white">
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        pilih provinsi terlebih dahulu
                      </span>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- edu -->
                <div class="my-5">
                  <span class="bg pa-1">Education</span>
                  <v-divider class="mt-n3" inset></v-divider>
                </div>
                <v-row v-if="campus">
                  <v-col cols="12" md="6">
                    <v-select
                      :options="campus.data"
                      :reduce="(item) => item.id"
                      v-model="university"
                      @search="(query) => (searchCampus = query)"
                      label="nama"
                      placeholder="University"
                      class="white"
                      @input="getMajors(university)"
                    ></v-select>
                    <small
                      v-if="!this.$v.university.required"
                      class="red--text"
                      >{{ univErr[0] }}</small
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-if="majors"
                      :options="majors.data"
                      :reduce="(item) => item.id"
                      v-model="selectedMajors"
                      @search="(query) => (searchCity = query)"
                      label="nama"
                      placeholder="Fields"
                      class="white"
                    ></v-select>
                    <v-select v-else placeholder="Fields" class="white">
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        pilih universitas terlebih dahulu
                      </span>
                    </v-select>
                    <small
                      v-if="!this.$v.selectedMajors.required"
                      class="red--text"
                      >{{ majorErr[0] }}</small
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Entry Year"
                      v-model="entryYear"
                      :error-messages="entryErr"
                      required
                      solo
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Graduate Year"
                      v-model="gradYear"
                      :error-messages="gradeErr"
                      required
                      solo
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      v-if="fields"
                      :options="fields.data"
                      :reduce="(item) => item.id"
                      v-model="selectedFields"
                      @search="(query) => (searchFields = query)"
                      label="nama"
                      placeholder="Fields Of Study"
                      class="white"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Diploma Number"
                      v-model="noIjazah"
                      :error-messages="noIjazahErr"
                      required
                      solo
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"> </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>

        <footer class="d-flex justify-end pa-2">
          <v-btn
            class="purple font-weight-bold"
            v-if="!loadInput"
            dark
            @click="saveData"
          >
            <v-icon> mdi-check-all </v-icon>
            Save</v-btn
          >
          <v-btn class="purple font-weight-bold" v-if="loadInput" dark
            ><v-progress-circular
              :width="5"
              color="white"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Loading...</v-btn
          >
        </footer>
        <div class="pa-1">
          <CropPhoto v-bind:dialogCrop="dialogCrop" @close="closeDialog" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import Waiting from "../../components/_base/Modal/waiting.vue";
import CropPhoto from "../../components/Psycholog/Modal/cropPhoto.vue";

export default {
  name: "fillData",
  mixins: [validationMixin],

  validations: {
    fullName: { required },
    ttl: { required },
    noHp: { required },
    selectedCity1: { required },
    selectedProvince1: { required },
    entryYear: { required },
    gradYear: { required },
    university: { required },
    selectedMajors: { required },
    noIjazah: { required },
  },
  computed: {
    ...mapState({
      listProvince: (state) => state.area.publicProvinces,
      city: (state) => state.area.filteredCity,
      loading: (state) => state.area.loading,
      campus: (state) => state.campus.universitas,
      majors: (state) => state.campus.majors,
      fields: (state) => state.fields.fields,
      photo: (state) => state.psycholog.photo,
      id: (state) => state.id,
      env: (state) => state.API_URL,
    }),

    nameErr() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.required &&
        errors.push("Nama lengkap tidak boleh kosong!");
      return errors;
    },

    ttlErr() {
      const errors = [];
      if (!this.$v.ttl.$dirty) return errors;
      !this.$v.ttl.required && errors.push("Tempat Lahir tidak boleh kosong!");
      return errors;
    },

    locErr() {
      const errors = [];
      if (!this.$v.selectedCity1.$dirty) return errors;
      !this.$v.selectedCity1.required &&
        errors.push("Kota tidak boleh kosong!");
      return errors;
    },

    provErr() {
      const errors = [];
      if (!this.$v.selectedProvince1.$dirty) return errors;
      !this.$v.selectedProvince1.required &&
        errors.push("Provinsi tidak boleh kosong!");
      return errors;
    },

    noHpErr() {
      const errors = [];
      if (!this.$v.noHp.$dirty) return errors;
      !this.$v.noHp.required && errors.push("No Hp tidak boleh kosong!");
      return errors;
    },

    entryErr() {
      const errors = [];
      if (!this.$v.entryYear.$dirty) return errors;
      !this.$v.entryYear.required &&
        errors.push("Tahun masuk tidak boleh kosong!");
      return errors;
    },

    gradeErr() {
      const errors = [];
      if (!this.$v.gradYear.$dirty) return errors;
      !this.$v.gradYear.required &&
        errors.push("Tahun lulus tidak boleh kosong!");
      return errors;
    },

    univErr() {
      const errors = [];
      if (!this.$v.university.$dirty) return errors;
      !this.$v.university.required &&
        errors.push("Universitas tidak boleh kosong!");
      return errors;
    },

    majorErr() {
      const errors = [];
      if (!this.$v.selectedMajors.$dirty) return errors;
      !this.$v.selectedMajors.required &&
        errors.push("Jurusan lengkap tidak boleh kosong!");
      return errors;
    },

    noIjazahErr() {
      const errors = [];
      if (!this.$v.noIjazah.$dirty) return errors;
      !this.$v.noIjazah.required &&
        errors.push("No ijazah lengkap tidak boleh kosong!");
      return errors;
    },
  },
  data() {
    return {
      searchFields: "",
      selectedFields: null,
      picture: null,
      fullName: "",
      ttl: "",
      noHp: 62,
      entryYear: "",
      gradYear: "",
      university: "",
      selectedMajors: null,
      noIjazah: "",
      searchProvince: "",
      searchCity: "",
      searchCampus: "",
      searchReligion: "",
      selectedGender: "",
      selectedReligion: "",
      selectedProvince1: null,
      selectedProvince2: null,
      selectedCity1: null,
      selectedCity2: null,
      selectedService: null,
      born_date: null,
      listCity1: null,
      listCity2: null,
      calendar1: false,
      dialogCrop: false,
      no_hp_show: 0,
      alamat_praktek: "",
      no_hp_praktek: "",
      website: "",
      no_website_show: 0,
      religion: [
        { state: "Buddha", val: "buddha" },
        { state: "Hindu", val: "hindu" },
        { state: "Islam", val: "islam" },
        { state: "Katolik", val: "katolik" },
        { state: "Konghucu", val: "konghucu" },
        { state: "Protestan", val: "protestan" },
        { state: "Lainya", val: "lainya" },
      ],
      service: [
        { state: "Online", val: "online" },
        { state: "Offline", val: "offline" },
        { state: "Both", val: "all" },
      ],
      gender: [
        { state: "Male", val: "Laki-laki" },
        { state: "Female", val: "Perempuan" },
        { state: "Prefer Not To Choose", val: "" },
      ],
      loadInput: false,
    };
  },
  components: {
    vSelect,
    Waiting,
    CropPhoto,
  },
  created() {
    this.$store.dispatch("area/publicProvince");
    this.$store.dispatch("campus/listUniversitas");
    this.$store.dispatch("fields/listFields");
    this.getPhoto();
  },
  methods: {
    getPhoto() {
      this.$store.dispatch("psycholog/getPhoto").then((data) => {
        console.log(data);
      });
    },

    closeDialog() {
      this.dialogCrop = false;
      this.getPhoto();
    },

    getCity(id, type) {
      console.log(type);
      if (type == "core") {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity1 = data;
        });
      } else {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity2 = data;
        });
      }
    },

    getMajors(id) {
      this.$store.dispatch("campus/listMajors", id);
    },

    upload(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = (f) => {
        this.picture = f.target.result;
      };
      fr.readAsDataURL(file);
      this.changePicture(file);
    },

    changePicture(pic) {
      const data = new FormData();
      data.append("objPhoto", pic);
      this.$store.dispatch("changePic", data).then((data) => {
        this.getPhoto();
        this.alert("success", data.message);
      });
    },

    validateImg() {
      if (!this.photo.photo_profile) {
        this.alert("error", "Foto harus di isi!");
      } else {
        this.saveData();
      }
    },

    saveData() {
      this.$v.$touch();
      if (this.$v.$invalid && !this.photo.photo_profile) {
        document.querySelector(".alert").style.display = "block";
        console.log(this.$v.$invalid);
        return;
      } else {
        this.loadInput = true;
        let data = new FormData();
        data.append("nama_lengkap", this.fullName);
        data.append("tempat_lahir", this.ttl);
        data.append("tanggal_lahir", this.born_date);
        data.append("layanan", this.selectedService);
        data.append("agama", this.selectedReligion);
        data.append("jenis_kelamin", this.selectedGender);
        data.append("kota1_id", this.selectedCity1);
        data.append("kota2_id", this.selectedCity2);
        data.append("no_hp", parseInt(this.noHp));
        data.append("no_hp_show", this.no_hp_show);
        data.append("alamat_praktek", this.alamat_praktek);
        data.append("no_hp_praktek", this.no_hp_praktek);
        data.append("website", this.website);
        data.append("website_show", this.no_website_show);
        data.append("tahun_masuk", this.entryYear);
        data.append("tahun_lulus", this.gradYear);
        data.append("jurusan_id", this.selectedMajors);
        data.append("universitas_id", this.university);
        data.append("nomor_ijazah", this.noIjazah);
        data.append("bidang_id", this.selectedFields);
        this.$store
          .dispatch("psycholog/fillData", data)
          .then((data) => {
            this.alert("success", data.message);
            this.loadInput = false;
            setTimeout(() => {
              this.$router.push("/psikolog/waiting");
            }, 2000);
          })
          .catch((err) => {
            this.loadInput = false;
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          });
      }
    },

    logout() {
      this.$store.dispatch("auth/logout").then((data) => {
        this.alert("success", `${data.data.message}`);
        this.$router.go("/");
      });
    },

    alert(type, title) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        duration: 5000,
      });
    },
  },
};
</script>

<style scoped>
._70w {
  width: 75%;
}
.bg {
  background: #eceff1;
}
.temp {
  width: 200px;
  border-radius: 50%;
  height: 200px;
  border: 1px dotted #000;
}
.pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.alert {
  display: none;
  overflow: hidden;
}
@media (max-width: 576px) {
  ._70w {
    width: 100%;
  }
}
@media (max-width: 768px) {
  ._70w {
    width: 100%;
  }
}
</style>
